/* eslint-disable object-shorthand */
/* eslint-disable no-magic-numbers */
// Disable the on-canvas tooltip
import $ from 'jquery'

let afterSubmit = false
const validator = $('.validate').validate({
  rules: {
    login: {
      required: true,
      minlength: 4,
      maxlength: 64
    },
    password: {
      required: true,
      minlength: 8
    }
  },
  errorElement: 'div',
  errorPlacement: function errorPlacement(error, element) {
    error.appendTo(element.parent('.form-group'))
  },
  validClass: '',
  errorClass: 'feedback invalid-feedback',
  highlight: function highlight(element) {
    $(element).addClass('is-invalid')
  },
  unhighlight: function unhighlight(element) {
    $(element).removeClass('is-invalid')
  },
  onkeyup: function onkeyup(element) {
    if (event.keyCode === 13 && afterSubmit) {
      afterSubmit = false
    } else {
      $('.summary-error').remove()
    }
    $(element).valid()
  },
  submitHandler: function submitHandler() {
    $('.summary-error').remove()
    const button = $('.ladda-button')
    const params = $('form').serialize()
    $.ajax({
      dataType: 'json',
      type: 'POST',
      url: 'api/login.json',
      data: params,
      beforeSend: function beforeSend() {
        $(button).prepend('<i class="icon-spinner icon-spin"></i>')
        $('.backdrop').show()
      },
      success: function success(data) {
        if (data.error) {
          $(button).find('i').remove()
          $('.backdrop').hide()
          if (typeof data.error === 'string') {
            $('form .form-group').last().append(`<div class="summary-error" style="">${data.error}</div>`)
            afterSubmit = true
          } else {
            validator.showErrors(data.error)
          }
        } else {
          window.location.href = 'index.html'
        }
      },
      error: function error() {
        window.console.log('Error! login.json')
      }
    })
  }
})

$(document).on('click', '.inside-control .btn-see', function (e) {
  e.preventDefault()
  const input = $(this).siblings('input')
  const password = input.is('input[type="password"]')
  const icon = $(this).find('i')
  icon.toggleClass('icon-eye', !password).toggleClass('icon-eye-crossed', password)
  if (password) {
    input.attr('type', 'text')
  } else {
    input.attr('type', 'password')
  }
})
